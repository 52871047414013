import { SearchDestination } from "./SearchDestination";
import { ProductHomeDetails } from "./ProductHomeDetails";

export const Search = () => {
  return (
    <section
      className="w-full h-[341px] flex flex-col place-content-center gap-4"
      style={{
        backgroundImage: `url("https://esimflys.com:8443/images/galeras-beach7.jpg")`,
        backgroundPosition: "center center",
      }}
    >
      <div className="grid grid-cols-2 grid-row">
        <div className="w-1/2">
            {/* <ProductHomeDetails/> */}
        </div>
        <div className="w-1/2">
            <SearchDestination/>
        </div>
      </div>
    </section>
  );
};
