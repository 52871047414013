import { Link } from "react-router-dom";

export const Header = () => {
    return( 
        <section className="flex flex-col items-center bg-warning-500" >
            <div className="w-[90%] h-10 grid grid-cols-2
            grid-rows-1 ">
                <div className="self-center">
                    <Link to="/" >
                        <img width={'100px'} src="https://esimflys.com:8443/images/logo-transparent.png"/>
                    </Link>
                </div>
                <div className="w-full grid grid-cols-4 grid-rows-1 place-items-center  ">
                    <Link to="/menu" className="font-bold" >Shop</Link>
                    <Link to="/about" className="font-bold" >About</Link>
                    <Link to="/services" className="font-bold" >Help</Link>
                    <Link to="/event" className="font-bold" >Sign In</Link>
                </div>
            </div>
        </section>
     );
}