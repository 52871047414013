import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

export const Rights = () => {
  return (
    <section className="w-full h-auto bg-black">
      <ul
        className="flex flex-col xl:flex-row justify-start gap-5 ml-6 py-2
                xl:gap-14"
      >
        <li className="text-white text-xs font-normal ">
          2024 © ESIMFLYS. ALL RIGHTS RESERVED
        </li>
        <li className="text-green-600 text-xs font-normal">PRIVACY POLICY</li>
        <li className="text-green-600 text-xs font-normal">TERMS OF USE</li>
        <li>
          <div className="size-auto flex flex-row justify-end items-end mb-4 gap-3">
            <div className="size-[16px] rounded-full bg-green-600 flex flex-row justify-center items-center ">
              <FaInstagram className="size-8 text-black " />
            </div>
            <div className="size-[16px] rounded-full bg-green-600 flex flex-row justify-center items-center">
              <FaFacebookF className="size-3 text-black " />
            </div>
            <div className="size-[16px] rounded-full bg-green-600 flex flex-row justify-center items-center">
              <FaTwitter className="size-8 text-black " />
            </div>
            <div className="size-[16px] rounded-full bg-green-600 flex flex-row justify-center items-center">
              <FaWhatsapp className="size-8 text-black " />
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};
