export const Footer = () => {
  return (
    <section className="mx-6 xl:my-6">
      <div className="w-full h-auto grid grid-cols-1 grid-rows-[auto_auto_auto] gap-11 xl:grid-cols-3 xl:grid-rows-1">
        <div className="w-full h-[226px] grid grid-cols-[60%_40%] grid-rows-1 justify-start items-start">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <div className="mb-5">
              <h1 className="text-sm font-bold text-gray-600">Support</h1>
            </div>
            <h1 className="text-sm font-normal">Help Center</h1>
            <h1 className="text-sm font-normal">Cancellation</h1>
            <h1 className="text-sm font-normal">Refund policies</h1>
            <h1 className="text-sm font-normal">Report issues</h1>
          </div>
        </div>
        <div className="w-full h-[226px] grid grid-cols-[60%_40%] grid-rows-1 justify-start items-start">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <div className="mb-5">
              <h1 className="text-sm font-bold text-gray-600">eSIM</h1>
            </div>
            <h1 className="text-sm font-normal">What is an eSIM</h1>
            <div className="text-sm font-normal">
              <div>eSIM Compatible</div> 
              <div>devices</div> 
            </div>
            <div className="text-sm font-normal">
              <div>How to install and</div> 
              <div>activate your eSIM</div> 
            </div>
            <h1 className="text-sm font-normal">FAQs</h1>
          </div>
        </div>
        <div className="w-full h-[226px] grid grid-cols-[60%_40%] grid-rows-1 border-b-[0.3px] border-gray-300 xl:border-r-[0.3px] xl:border-b-0">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <div className="mb-5">
              <h1 className="text-sm font-bold text-gray-600">eSIMFlys</h1>
            </div>
            <h1 className="text-sm font-normal">Newsroom</h1>
            <div className="text-sm font-normal">
              <div>New features</div> 
              <div>Careers</div> 
            </div>
            <div className="text-sm font-normal">
              <div>Investors</div> 
              <div>Gift cards</div> 
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
