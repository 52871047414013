export const NamesPoints = () => {
    return (
        <div className="w-full  ">
            <h2 className="w-full text-center text-white ">
                FN
            </h2>
            <h2 className="w-full text-center text-white ">
                BH
            </h2>
            <h2 className="w-full text-center text-white ">
                SERVE
            </h2>
        </div>
    );
}