import { FrontPage } from "../components/FrontPage";
import { Info } from "../components/Info";
import { News } from "../components/News";
import { Plans } from "../components/Plans";
import { Search } from "../components/Search";
import { HomePlans } from "../components/HomePlans";
import { Header } from "../components/Header";

export const Home = () => {
    return (
        <section>
            <div>
                <Header/>
                <FrontPage />
                <HomePlans />
                {/* <Plans /> */}
                {/* <News /> */}
                {/* <Info /> */}
                <Search />
            </div>
        </section>
    );
}