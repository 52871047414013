export const Vs = () => {
    return( 
        <section className="w-full">
            <div className="size">
                <div className="w">
                    <h1>
                        32
                    </h1>
                </div>
                <div className="w">
                    <h1>
                        Luis Ronald
                    </h1>
                </div>
            </div>
        </section>
     );
}