import { useState, useEffect } from "react";
import { Players } from "./Players";

export const TableMetrics = () => {
    const [metricsPlayer1, setMetricsPlayer1] = useState(null);
    const [metricsPlayer2, setMetricsPlayer2] = useState(null);

    //GET Methods calling
    useEffect(() => {
        fetch('https://messonstats.com:8443/product')
            .then((response) => response.json())
            .then((responseData) => {
                // Assuming the response structure is similar to your initial metricsPlayer1 and metricsPlayer2 objects
                setMetricsPlayer1(responseData.metricsPlayer1);
                setMetricsPlayer2(responseData.metricsPlayer2);
                console.log(responseData)
            })
            .catch((error) => {
                console.log('Error fetching and parsing data', error);
            });
    }, []);

    console.log(metricsPlayer1)

    return (
        <section className="w-[90%] ">
            {metricsPlayer1 && <Players PositionPlayer={true} PlayerData={metricsPlayer1} />}
            {metricsPlayer2 && <Players PositionPlayer={false} PlayerData={metricsPlayer2} />}
        </section>
    );
}
