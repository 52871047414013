export const FrontPage = () => {
  return (
    <section className="w-full h-[85vh] max-h-[800px] flex items-center justify-center px-6 md:px-21 bg-mobile-front sm:bg-desktop-front bg-cover bg-center">
      <div className="text-center text-black">
        <p className="text-2xl font-semibold text-black">
          Using this discount code #summersalediscount,
        </p>
        <p className="text-2xl font-semibold text-black">
          for destination you want.
        </p>
        <a
          href="#"
          className="inline-block mt-4 px-9 py-3 rounded-3xl bg-secondary-400 font-semibold text-white text-lg"
        >
          Shop Now
        </a>
      </div>
    </section>
  );
};
